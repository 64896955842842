/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import ogImage from "../images/og-image.png";
import ogImageSmall from "../images/og-image-sm.png";

function SEO({ description, lang, meta, title }) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description

    const metaTitle = title || site.siteMetadata.title

    const [url, setUrl] = useState('');

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            titleTemplate={`${site.siteMetadata.title}`}
            meta={[
                {
                    title: `title`,
                    content: metaTitle,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    property: `og:url`,
                    content: `${url}`
                },
                {
                    property: `og:image`,
                    content: ogImage
                },
                {
                    property: `og:image`,
                    content: ogImageSmall
                }
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `ru`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
